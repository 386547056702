@font-face {
  font-family: "RalewayMedium";
  src: url("./fonts/Raleway-Medium.ttf") format("truetype"); /* IE9 Compat Modes */
}
@font-face {
  font-family: "RalewayRegular";
  src: url("./fonts/Raleway-Regular.ttf") format("truetype"); /* IE9 Compat Modes */
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background-color: black;
  z-index: 1;
  color: white;
}

a.mantine-NavLink-root {
  padding: 0;
}

a.mantine-NavLink-root:hover {
  background-color: transparent;
}

a.mantine-NavLink-root:where([data-active], [aria-current="page"]) {
  color: unset;
  position: relative;
}

a.mantine-NavLink-root:where([data-active], [aria-current="page"]):after {
  content: "";
  height: 1px;
  background-color: white;
  width: 100%;
  position: absolute;
  bottom: -0.1rem;
}

.header {
  justify-content: flex-start;
  align-items: center;
  z-index: 10;
  position: fixed;
  top: 0;
  max-width: 100vw;
}

.header > nav {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 1.5rem;
  position: relative;
}

#header-logo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.mantine-Paper-root {
  width: 100vw;
}

.page-container {
  flex-direction: column;
  min-height: calc(100vh - 8rem);
  margin-top: 8rem;
}

.home-container {
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  height: 85vh;

  position: absolute;
  top: 0;
  width: 100%;
  padding-bottom: 5rem;
  text-align: center;
}

#home-reel-wrapper {
  position: absolute;
  z-index: 0;
  top: 0;
  max-width: 100vw;
  overflow-x: hidden;

  display: flex;
  justify-content: center;
}

#home-reel {
  @media (orientation: portrait) {
    width: auto;
    height: 85vh;
  }

  @media (orientation: landscape) {
    width: 100vw;
    height: auto;
  }
}

a.unstyled,
a.unstyled:visited {
  text-decoration: none;
  color: inherit;
  outline: 0;
}

.home-container {
  @media (orientation: portrait) {
    width: 100vw;
    height: 85vh;
  }

  @media (orientation: landscape) {
    width: 100vw;
    height: 53vw;
  }
}

.work-container {
  display: flex;
  width: 100%;
  margin-bottom: 4rem;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 1rem;
}

.work-container a {
  text-align: center;
  text-decoration: underline;
  margin-top: 1rem;
}

.work-video {
  border: 0;
  margin-bottom: 8rem;

  @media (orientation: portrait) {
    width: 100vw;
    height: 56vw;
  }

  @media (orientation: landscape) {
    width: 50vw;
    height: 28vw;

    min-width: 560px;
    min-height: 315px;

    max-width: 850px;
    max-height: 478px;
  }
}

.contact-container {
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.mobile-nav {
  flex-direction: column;
  align-items: center;
}

.mobile-nav a {
  text-align: center;
}

.mobile-nav .mantine-NavLink-root {
  background-color: unset;
}

.mobile-nav .mantine-NavLink-label {
  font-size: 1.5rem;
}

.mobile-nav .mantine-NavLink-root::after {
  width: 50%;
  left: 50%;
  transform: translateX(-50%);
}
